function Footer() {
  return (
    <footer className="footer">
      <p className="footerText">
        {/* <a className="footerTag" href="https://www.tibetakyurekli.com"> */}
        Designed & Developed by Tibet Akyurekli 2023
        {/* </a> */}
      </p>
    </footer>
  );
}

export default Footer;
